<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    :header="'Imagenes Producto : ' + (detalle.descripcion ?? 'cargando ...')"
    :modal="true"
    class="p-fluid"
    @hide="cerrarModal"
    :loading="loading"
  >
    <div class="col-12">
      <FileUpload
        mode="basic"
        name="demo[]"
        accept="image/*"
        :maxFileSize="1000000"
        :customUpload="true"
        chooseLabel="Cargar"
        @uploader="onUpload"
        :auto="true"
      />
    </div>
    <div class="card-container flex">
      <div
        v-for="imagen in imagenes"
        :key="imagen.id"
        class="col-4 shadow-4 m-3 surface-card"
      >
        <Image
          :src="'data:image/png;base64, ' + imagen.image"
          width="150"
          preview
        ></Image>
        <div class="flex justify-between">
          <div class="col-6">
            <Button
              icon="pi pi-trash"
              label="Quitar"
              class="p-button-rounded p-button-danger"
              @click="eliminarImagen(imagen.id)"
              v-tooltip.top="'Quitar Imagen'"
            />
          </div>
          <!-- <div class="col-6">
            <Button
              icon="pi pi-check"
              class="p-button-rounded p-button-success"
              @click="cambiarImagen(imagen.id)"
              v-tooltip.top="'Cambiar Imagen Principal'"
            />
          </div> -->
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="ACEPTAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="cerrarModal"
      />
    </template>
  </Dialog>
</template>

<script>
import ProductService from "@/service/ProductService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    producto_id: {
      type: Number,
      default: 0,
    },
  },
  productoService: null,
  data() {
    return {
      mostrarModal: this.show,
      producto: this.producto_id,
      detalle: [],
      imagenes: [],
      loading: true,
    };
  },
  created() {
    this.productoService = new ProductService();
  },
  methods: {
    eliminarImagen(imagen_id) {
      this.productoService.deleteImage(imagen_id).then(() => {
        this.buscarProducto();
        this.$toast.add({
          severity: "success",
          summary: "Actualizado",
          detail: "Imagen eliminada correctamente",
          life: 3000,
        });
      });
    },
    cerrarModal() {
      this.mostrarModal = false;
      /*  this.detalle = [];
      this.imagenes = []; */
      this.$emit("closeModal");
      this.$emit("actualizarListado");
    },
    buscarProducto() {
      this.productoService.getProductoImages(this.producto_id).then((data) => {
        this.detalle = data.producto;
        this.imagenes = data.images;
        this.loading = false;
      });
    },
    onUpload(event) {
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("imagen", archivo);
      formData.append("producto_id", this.producto_id);

      this.productoService
        .uploadImage(formData)
        .then(() => {
          this.buscarProducto();
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: "Imagen cargada correctamente",
            life: 3000,
          });
          this.$emit("actualizarListado");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    show: function (val) {
      this.mostrarModal = val;
    },
    producto_id: function (val) {
      this.producto = val;
      this.buscarProducto();
    },
  },
};
</script>